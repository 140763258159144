import { useEffect, useState } from "react";
import classnames from "classnames";
import { useRouter } from "next/router";

import { DispensaryTagsEnum } from "custom-types/Dispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import useFilters from "hooks/useFilters";
import isDispensaryDualLicense from "utils/dispensary/isDispensaryDualLicense";
import { dispensaryHref } from "utils/dispensaryUrls";
import { scrollToTop } from "utils/scrollPosition";
import { trackMenuEvent } from "utils/trackEvent";

import SearchInput from "components/SearchInput";
import MenuTypeSelection from "components/Shared/MenuTypeSelection";

type Props = {
  className?: string;
  dispensaryId: number;
  dispensaryRetailType: string;
  dispensarySlug: string;
  dispensaryTags: DispensaryTagsEnum[];
  routeToMenu?: boolean;
};

const SearchBar: React.FC<Props> = ({
  className,
  dispensaryId,
  dispensaryRetailType,
  dispensarySlug,
  dispensaryTags,
  routeToMenu = false,
}) => {
  const countryCode = useDomainCountryCode();
  const isDualLicenseDispensary = isDispensaryDualLicense(dispensaryTags);

  const { selectFilterValue, addFilter, addDispensaryFilter } = useFilters({
    shallowRouting: false,
  });
  const { push, query } = useRouter();

  const queryParam = selectFilterValue("q");
  const [searchValue, setSearchValue] = useState(queryParam || "");

  useEffect(() => {
    if (!queryParam && searchValue) {
      setSearchValue("");
    }
  }, [queryParam]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    if (searchValue) {
      setSearchValue("");
    }
    if (queryParam) {
      addFilter({ page: undefined, q: undefined });
      scrollToTop("smooth");
    }
  };

  const pushNewQuery = (newQuery: string) => {
    const menuType = query.menu_type ? `&menu_type=${query.menu_type}` : "";
    if (routeToMenu) {
      const link = dispensaryHref({
        additionalPath: `/menu?q=${newQuery}${menuType}`,
        countryCode,
        retailType: dispensaryRetailType,
        slug: dispensarySlug,
      });

      push(link);
    } else {
      addFilter({ page: undefined, q: newQuery });
      scrollToTop("smooth");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  const handleSubmit = (e: any) => {
    // If function was called from onKeyPress, only proceed with Enter
    if (e.key && e.key !== "Enter") {
      return;
    }

    // Remove unwanted characters from search query
    const formattedValue = String(searchValue).replace(/[^\w\s-_]/g, "");

    if (formattedValue !== queryParam) {
      pushNewQuery(formattedValue);
      trackMenuEvent("Search", formattedValue);
    }
  };

  const handleMenuChange = (value?: string) => {
    addDispensaryFilter("menu_type", value);
  };

  return (
    <>
      <div className={classnames(className)}>
        <div className="flex">
          <SearchInput
            inputProps={{
              onChange: handleChange,
              onKeyDown: handleSubmit,
              value: searchValue,
            }}
            clearButtonProps={{
              onClick: handleClear,
            }}
            searchButtonProps={{
              onClick: handleSubmit,
            }}
          />

          <MenuTypeSelection
            dispensaryId={dispensaryId}
            className="ml-sm text-right"
            isDualLicense={isDualLicenseDispensary}
            onMenuTypeChange={handleMenuChange}
            showSelectInput={true}
          />
        </div>
      </div>
    </>
  );
};

export default SearchBar;
