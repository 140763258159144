import { useRef, useState } from "react";
import dynamic from "next/dynamic";

import { Dispensary } from "custom-types/Dispensary";

import Image from "components/Image";

const DispensaryPhotosLightBox = dynamic(
  () => import("./DispensaryPhotosLightBox"),
);

const PhotoOverview: React.FC<{
  className?: string;
  dispensaryName: Dispensary["name"];
  dispensarySlug: Dispensary["slug"];
  initialPhotos?: Dispensary["photos"];
}> = ({ className, dispensaryName, dispensarySlug, initialPhotos }) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const initialLigthboxIndex = useRef(0);

  if (!initialPhotos?.length) {
    return null;
  }

  const toggleLightbox = (index = 0) => {
    initialLigthboxIndex.current = index;
    setShowLightbox(!showLightbox);
  };

  return (
    <>
      <div className={className}>
        {showLightbox && (
          <DispensaryPhotosLightBox
            initialPhotos={initialPhotos}
            initialIndex={initialLigthboxIndex.current}
            dispensarySlug={dispensarySlug}
            onClose={toggleLightbox}
          />
        )}
        <h2 className="mb-sm heading--m font-extrabold">{`Photos of ${dispensaryName}`}</h2>
        <div className="row mb-md">
          {initialPhotos.slice(0, 4).map((photo, index) => (
            <button
              className="col-1/2 md:col-1/4 relative mb-md md:mb-none"
              key={photo.imageUrl}
              onClick={() => toggleLightbox(index)}
              aria-label="View enlarged photo in lightbox"
            >
              <Image
                sizes={[250, 360, 175, 240, null, 250]}
                src={photo.imageUrl}
                alt={dispensaryName}
                ratio="square"
              />
            </button>
          ))}
        </div>
        <button
          onClick={() => toggleLightbox()}
          className="font-bold text-green text-sm"
        >
          Show all photos
        </button>
      </div>
    </>
  );
};

export default PhotoOverview;
